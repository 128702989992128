<template>
<div class="licensees sitemap">
    <section class="sec__licensees">
        <div class="wCenter">
            <h3 class="sec__licensees__title">站点地图</h3>
            <div v-if="navs&&navs.length>1">
                <ul class="">
                    <!--导航类型 （1：外链，2：内部路由，）-->
                    <li v-for="(item,i) in navs" :key="i" class="first">
                        <a @click="openPage(item)">{{item.title}}</a>
                        <ol v-if="item.children&&item.children.length>0" class="second">
                            <li v-for="(child,j) in item.children" :key="j">
                                <a @click="openPage(child)">{{child.title}}</a>
                            </li>
                        </ol>
                    </li>
                </ul>
            </div>
            <NoData v-else></NoData>
        </div>
    </section>
</div>
</template>

<script>
import util from "@/libs/util.js";
import {
    pagesRouter
} from "@/router/router";
import {
    getChannelList,
} from "@/api/api";
export default {
    data() {
        return {
            navs: []
        };
    },
    methods: {
        init() {
            //设备(1:PC端,2:移动端)
            getChannelList().then(res => {
                if (res.success) {
                    this.navs = res.result;
                }
            });
        },
        openPage(v) {
            if (v.type == '1') { //外链
                window.open(v.link, v.newWin ? '_blank' : '_self');
            } else if (v.type == '2') {
                //内链路由
                util.pageChange(this.$router, `/${v.code}`, {}, v.newWin ? '_blank' : '_self');
            } else if (v.type == '3') {
                //在线编辑页面
                util.pageChange(this.$router, `/page/${v.code}.html`, {}, v.newWin ? '_blank' : '_self');
            }

        }
    },
    computed: {},
    mounted() {
        this.init();
    }
};
</script>

<style lang="scss">
.sitemap {
    .first {

        width: 100%;

        a {
            color: #333;
            line-height: 3em;
            padding-bottom: .1rem;
            border-bottom: 1px dashed #b2b4b6;

            &:hover {
                border-bottom: 1px solid #333;
            }
        }

        >a {
            font-size: .22rem;
        }

        .second {
            display: flex;
            align-items: center;

            li {
                margin-left: .3rem;
            }

            a {
                font-size: .18rem;
            }
        }
    }

}
</style>
